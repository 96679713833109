<template>
	<div>
		<div class="company" v-if="company">
			<div class="wrapper">
				<div class="company_row">
					<div class="company_col-1">
						
						<div class="company_image">
							<img :src="$hostname + company.company_logo" alt="">
						</div>
						<h1 class="company_name __mobile">{{ company.name }}</h1>
						<div class="company_info" v-if="cnt">Курсов опубликовано - {{ cnt }}</div>
						<div class="company_description">{{ company.description }}</div>
					</div>
					<div class="company_col-2">
						<h1 class="company_name">{{ company.name }}</h1>
						
						<div class="courses" v-if="courses">
							<div class="courses_row">
								<div class="courses_col __col-2"  v-for="course in courses" :key="course.id">
									<course-item :course="course"></course-item>
								</div>
							</div>
						</div>
						<div class="loading" v-else></div>
					</div>
				</div>
			</div>
		</div>
		<div v-else class="loading"></div>
	</div>
</template>

<script>
	import CourseItem from "../components/Course/Item";
	
	export default {
		components: {
			CourseItem
		},
		data() {
			return {
				company: null,
				id: this.$route.params.id,
				courses: null,
				cnt: null
			}
		},
		
		created() {
			this.axios.get('/edu/company/' + this.id)
				.then(({data}) => {
					this.company = data;
				});
			this.axios.get('/edu/courses?&company_id=' + this.id).then(({data}) => {
				this.courses = data.data;
				this.cnt = data.cnt;
			});
		}
	}
</script>

<style scoped>

</style>