<template>
	<div>
		<div class="course-banner __empty" v-if="course">
			<div class="wrapper">
				<div class="course-banner_item __min" :style="'background-image: url(' + $hostname + course.image + ')'">
					<div class="course-banner_info">
						<router-link :to="{name: 'my-course-lessons', params: {id: course.id}}">{{ course.name }}</router-link>
						»
						<router-link :to="{name: 'my-course-lesson', params: {course: course.id, lesson: lesson.id}}">{{ lesson.name }}</router-link>
						»
						<span>{{ material.name }}</span>
					</div>
					<h1 class="course-banner_title">{{ material.name }}</h1>
				</div>
			</div>
		</div>
		
		<div class="material" v-if="material">
			<div class="wrapper">
				<div v-if="material.content_type === 'video'">
					<vue-plyr :options="playerOptions">
						<video controls crossorigin playsinline>
							<source v-if="material.video480_url" size="480" :src="$hostname + this.material.video480_url" type="video/mp4" />
							<source size="1080" :src="$hostname + this.material.content" type="video/mp4" />
						</video>
					</vue-plyr>
				</div>
				<div v-else-if="material.content_type === 'text'">
					<div class="material_text" :class="{__70: courseId == 70}" v-html="material.content"></div>
				</div>
				<div v-else-if="material.content_type === 'html'">
					<div class="material_text" v-html="htmlLesson" v-if="htmlLesson"></div>
				</div>
				<div v-else-if="material.content_type === 'test'">
					<div class="material_test">
						<test :url="material.content"></test>
					</div>
				</div>
				<div v-else-if="material.content_type === 'file'">
					<div class="material_file">
						<a target="_blank" :href="$hostname + material.content">
							<i class="ico icon-download"></i>
							<span>Скачать "{{ material.name }}"</span>
						</a>
					</div>
				</div>
			</div>
			<div style="clear: both"></div>
		</div>
		
		<div class="loading" v-if="!course"></div>
		
		<div class="wrapper back-arrows" v-if="course">
			<div class="back-arrow-wrapper">
				<router-link v-if="prevLesson && !prevMaterial" :to="{name: 'my-course-lesson', params: {lesson: prevLesson.id, course: this.courseId}}" class="back-arrow __prev">
					<i class="ico icon-side-arrow"></i>
					Предыдущий урок
				</router-link>
				
				<router-link v-if="prevMaterial" :to="{name: 'my-course-lesson-material', params: {course: course.id, lesson: lesson.id, material: prevMaterial.id}}" class="back-arrow __prev">
					<i class="ico icon-side-arrow"></i>
					Назад
				</router-link>
				<router-link v-if="nextMaterial" :to="{name: 'my-course-lesson-material', params: {course: course.id, lesson: lesson.id, material: nextMaterial.id}}" class="back-arrow __next">
					<i class="ico icon-side-arrow"></i>
					Далее
				</router-link>
				
				<router-link v-if="nextLesson && !nextMaterial" :to="{name: 'my-course-lesson', params: {lesson: nextLesson.id, course: this.courseId}}" class="back-arrow __next">
					<i class="ico icon-side-arrow"></i>
					Следующий урок
				</router-link>
			</div>
		</div>
	</div>
</template>

<script>
	import 'video.js/dist/video-js.css'
	import './../assets/css/vue-video.css'
	import './../assets/css/material70.css'
	import Test from "../components/Test/Test";
	import vuePlyr from 'vue-plyr';
	import 'vue-plyr/dist/vue-plyr.css'
	import $ from 'jquery'
	
	export default {
		components: {
			Test,
			vuePlyr
		},
		
		data() {
			return {
				course: null,
				lesson: null,
				courseId: this.$route.params.course,
				lessonId: this.$route.params.lesson,
				materialId: this.$route.params.material,
				material: null,
				prevMaterial: null,
				nextMaterial: null,
				prevLesson: null,
				nextLesson: null,
				htmlLesson: null,
				
				playerOptions: {
					i18n: {
						speed: 'Скорость',
						normal: 'Обычная',
						quality: 'Качество'
					}
				}
			}
		},

		created() {
			if ([99, 100, 101, 102].includes(parseInt(this.courseId))) {
				if (!this.auth.subscribed_en) {
					this.$router.push({name: 'subscribe-en'});
					return;
				}
			}
			
			this.axios.get('/edu/course/' + this.courseId).then(({data}) => {
				if (![99, 100, 101, 102].includes(parseInt(this.courseId))) {
					if (!data.course.is_free &&  this.auth.subscribed === 0) {
						this.$router.push({name: 'subscribe'});
						return;
					} else if (!data.course.is_free && this.auth.subscribed === 2) {
						this.$router.push({name: 'no-money'});
						return;
					}
				}
				
				
				this.course = data.course;
				
				data.lessons.forEach((lesson, key) => {
					if (lesson.id == this.lessonId) {
						this.lesson = lesson;

						data.lessons.current = key;
					}
				});

				this.lesson.materials.forEach((material, key) => {
					if (material.id == this.materialId) {
						this.material = material;
						
						if (this.material.content_type === 'words') {
							this.$router.push({
								name: 'english-card',
								params: {
									category: this.material.content
								}
							})
						}
						
						this.axios.get('/edu/video/' + this.material.content)
							.then(({data}) => {
								this.htmlLesson = data;
							})

						this.lesson.materials.current = key;
					}
				});


				this.prevMaterial = this.lesson.materials.prev();
				this.nextMaterial = this.lesson.materials.next();
				
				this.prevLesson = data.lessons.prev();
				this.nextLesson = data.lessons.next();
			});
			
			this.axios.get('/edu/fix_mat/' + this.materialId);
		},
	}
</script>
