<template>
	<div>
		<div v-if="course">
			<div class="course-banner">
				<div class="wrapper">
					<div class="course-banner_item" :style="'background-image: url(' + $hostname + course.image + ')'">
						<div class="course-banner_info">{{ course.category }}</div>
						<h1 class="course-banner_title">{{ course.name }}</h1>
						<div v-if="course.progress === null">
							<a @click.prevent="addCourse" href="" class="button __large __white">Записаться на курс</a>
						</div>
						<div v-else>
							<a @click.prevent="goToCourse" href="" class="button __large __white">Продолжить прохождение</a>
						</div>
					</div>
				</div>
			</div>
			
			<div class="course-info">
				<div class="wrapper">
					<tab :headings="courseHeadings" active-key="description">
						<template v-slot:description>
							<div class="course-info_item">
								<div class="course-info_description">
									<div class="h2-like">Описание курса</div>
									
									<div class="course-info_description-row">
										<pre class="course-info_description-col">{{ course.description }}</pre>
									</div>
								</div>
							</div>
						</template>
						<template v-slot:schedule>
							<div class="course-info_item">
								<div class="h2-like">Содержание курса</div>
								
								<div class="course-schedule_items">
									<div class="course-schedule_item">
										<div class="course-schedule_item">
											<div class="course-schedule_title">Список уроков</div>
											
											<div class="course-schedule_content">
												<ul>
													<li v-for="(lesson, key) in lessons" :key="key">
														{{ lesson.name }}
													</li>
												</ul>
											</div>
										</div>
									</div>
								</div>
							</div>
						</template>
						<template v-slot:author>
							<div class="course-info_item">
								<div class="h2-like">Авторы курса</div>
								
								<div class="course-info_authors">
									<div class="course-info_authors-col" v-for="lector in course.lectors" :key="lector.name">
										<div class="course-info_author">
											<div class="course-info_author-image">
												<img :src="$hostname + lector.image" alt="">
											</div>
											<div class="course-info_author-name">
												{{ lector.name }}
											</div>
											<pre class="course-info_author-text">{{ lector.description }}</pre>
										</div>
									</div>
								</div>
							</div>
						</template>
						<template v-slot:plus>
							<div class="course-info_item">
								<div class="h2-like">Преимущества</div>
								
								<ul class="course-info_list">
									<li><i class="ico icon-success-large"></i><span>Дистанционно</span> Обучение проходит в онлайн-формате. Занимайтесь где угодно, с любого доступного вам устройства.</li>
									<li><i class="ico icon-success-large"></i><span>Индивидуальный темп обучения</span> Вы сами решаете, когда заниматься и сколько времени уделять обучению. Не нужно подстраиваться под группу или преподавателя.</li>
									<li><i class="ico icon-success-large"></i><span>Системный подход к образованию</span> Вы получите актуальные систематизированные знания от мастеров своего дела. Все программы обучения гибки и адаптивны под ваши потребности, а все материалы для образования будут доступны онлайн уже внутри курса.</li>
									<li><i class="ico icon-success-large"></i><span>Учиться просто</span> Все материалы курса подаются последовательно, даже начинающие смогут легко освоить любой курс. Вы можете повторно пересматривать видео лекций или презентаций если вам что-то стало не понятно.</li>
								</ul>
							</div>
						</template>
					</tab>
					
					<div class="course-info_button">
						<div v-if="course.progress === null">
							<a @click.prevent="addCourse" href="" class="button __large">Записаться на курс</a>
						</div>
						<div v-else>
							<a @click.prevent="goToCourse" href="" class="button __large">Продолжить прохождение</a>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-else class="loading"></div>
	</div>
</template>

<script>
	import Tab from "../components/Tab/Tab";
	import Schedule from "../components/Course/Schedule";
	
	export default {
		components: {
			Tab,
			Schedule
		},
		data() {
			return {
				id: this.$route.params.id,
				course: null,
				lessons: null,
				courseHeadings: {
					description: 'Описание курса',
					schedule: 'Содержание курса',
					author: 'Авторы',
					plus: 'Преимущества',
				},
			}
		},
		
		created() {
			localStorage.setItem('courseId', this.id)
			
			this.axios.get('/edu/course/' + this.id).then(({data}) => {
				if (data.course.is_promo && !this.auth.have_promo) {
					this.$router.push({name: 'home'});
					return;
				}
				
				this.course = data.course;
				this.lessons = data.lessons;
			});
		},
		
		methods: {
			addCourse() {
				if (!this.course.is_free && !this.auth.subscribed) {
					this.$router.push({name: 'subscribe'});
					return;
				}
				
				this.axios.get('/edu/fix_course/' + this.course.id)
					.then(() => {
						this.$router.push({
							name: 'my-course-lessons',
							params: {
								id: this.course.id
							}
						})
					});
			},
			goToCourse() {
				this.$router.push({
					name: 'my-course-lessons',
					params: {
						id: this.course.id
					}
				})
			}
		}
	}
</script>

<style scoped>

</style>
