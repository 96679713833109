<template>
	<div>
		<div class="banner">
			<div class="wrapper">
				<div class="banner_item" style="background-image: url('/img/banner.png')">
					<div class="banner_text __mb0">Курсы, доступные для Вас</div>
				</div>
			</div>
			
			<div class="promo" v-if="!auth.have_promo">
				<div class="wrapper">
					<div class="promo_content">
						<div class="promo_header">
							<img src="/img/gift.png" alt="">
							
							<div class="promo_title">Акцивация промокода</div>
						</div>
						<div class="promo_body">
							<div>
								<input type="text" class="promo_input" placeholder="Введите промокод" v-model="code">
							</div>
							<div>
								<button class="button __large" @click="activate">Активировать</button>
							</div>
							
							<div class="loading" v-if="loading"></div>
							
							<div class="promo_error" v-if="error">Ошибка активации</div>
						</div>
					</div>
				</div>
			</div>
			
			<div class="courses __padding" v-if="auth.have_promo">
				<div class="wrapper">
					<div class="courses_row">
						<div class="courses_col"  v-for="course in courses" :key="course.id">
							<course-item :course="course" :promo="true"></course-item>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import CourseItem from "../components/Course/Item";
	
	export default {
		components: {
			CourseItem
		},
		
		data() {
			return {
				courses: [],
				loading: false,
				error: false,
				code: '',
			}
		},
		
		created() {
			this.axios.get('/edu/courses?is_promo=1').then(({data}) => {
				this.courses = data.data;
			});
		},

		methods: {
			activate() {
				if (this.loading) return;
				
				this.loading = true;
				
				this.axios.get('/edu/fix_code/' + this.code).then(({data}) => {
					this.loading = false;
					
					this.error = data.error === 'bad code';
					
					if (data.status === 'ok') {
						location.reload();
					}
				});
			}
		}
	}
</script>

<style scoped>

</style>
