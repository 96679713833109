<template>
	<div>
		<div class="course-banner">
			<div class="wrapper">
				<div class="course-banner_item" :style="'background-image: url(/img/eng.jpg)'" v-if="$route.name === 'english-my-cards'">
					<div class="course-banner_info">
						<router-link :to="{name: 'english'}">Английский язык</router-link>
					</div>
					
					<h1 class="course-banner_title">Избранное</h1>
					
					<div class="course-banner_buttons" >
						<router-link v-if="$route.name === 'english-my-cards'" :to="{name: 'english-cards'}" class="course-banner_button __margin">Все наборы</router-link>
						<router-link v-if="$route.name === 'english-cards'" :to="{name: 'english-my-cards'}" class="course-banner_button __margin">Избранное</router-link>
					</div>
				</div>
				
				<div v-else class="course-banner_item" :style="'background-image: url(/img/eng.jpg)'">
					<div class="course-banner_info">
						<router-link :to="{name: 'english'}">Английский язык</router-link>
						»
						<router-link :to="{name: 'english-cards'}">Наборы слов</router-link>
					</div>
					
					<h1  class="course-banner_title __mb0">{{ categoryName }}</h1>
					
					<div class="course-banner_buttons __mt">
						<a v-if="!allAdded" href="" @click.prevent="addAll" class="course-banner_button">Сохранить в избранное</a>
						<span v-else class="course-banner_button __disable">В избранном</span>
					</div>
				</div>
			</div>
		</div>
		
		<div class="cards-list">
			<div class="wrapper">
				<div class="cards-list_top">
					<button class="cards-list_button" @click="print()">Распечатать</button>
					<button class="cards-list_button __2" @click="setActiveCard(cards[0])">Учить</button>
					<div class="cards-list_types">
						<span class="cards-list_grid-type" :class="{active: type === 'grid'}" @click="changeType('grid')"></span>
						<span class="cards-list_list-type" :class="{active: type === 'list'}" @click="changeType('list')"></span>
					</div>
				</div>
				
				<div class="cards-list_row" :class="{__grid: type === 'grid', __list: type === 'list'}">
					<div class="cards-list_col" v-for="card in cards" :key="card.id">
						<div class="cards-list_item" @click.stop="setActiveCard(card)">
							<div class="cards-list_image">
								<img :src="$hostname + card.image" alt="">
							</div>
							
							<div class="cards-list_data">
								<div class="cards-list_words">
									<div class="cards-list_en">{{ card.en}}</div>
									<div class="cards-list_ru">{{ card.ru }}</div>
								</div>
								<div class="cards-list_icons">
									<div class="cards-list_icon __1" @click.stop="loadAudio(card)"></div>
									<div class="cards-list_icon __2" :class="{active: isFavorite(card)}" @click.stop="toggleFavorite(card)"></div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		
		<div class="card-block" v-if="activeCard">
			<div class="card-block_main">
				<div class="card-block_close" @click="activeCard = null"></div>
				
				<div class="card-block_image">
					<img :src="$hostname + activeCard.image" alt="">
				</div>
				<div class="card-block_content">
					<div class="card-block_en">{{ activeCard.en }}</div>
					<div class="card-block_ru">{{ activeCard.ru }}</div>
					
					<div class="card-block_arrows">
						<a href="" class="back-arrow __prev __mobile-only" @click.prevent="changeActiveCard(-1)">
							<i data-v-ab84afdc="" class="ico icon-side-arrow"></i>
						</a>
						<div class="card-block_icon __1" @click="loadAudio(activeCard)"></div>
						<div class="card-block_icon __2" @click.stop="toggleFavorite(activeCard)" :class="{active: isFavorite(activeCard)}"></div>
						<a href="" class="back-arrow __next __mobile-only" @click.prevent="changeActiveCard(1)">
							<i data-v-ab84afdc="" class="ico icon-side-arrow"></i>
						</a>
					</div>
				</div>
			</div>
			
			<div class="card-block_layer" @click="activeCard = null"></div>
		</div>
		
		<audio controls src="" v-show="false"></audio>
		
		<div class="english_popup" v-if="addedToFavorite">
			Добавлено в избранное
			
			<span class="english_popup-close" @click="addedToFavorite = false"></span>
		</div>
	</div>
</template>

<script>
	import $ from 'jquery'
	
	export default {
		data() {
			return {
				category: this.$route.params.category,
				categoryName: '',
				currentAudio: '',
				activeCard: null,
				cards: [],
				favorites: [],
				type: 'grid',
				addedToFavorite: false
			}
		},
		
		created() {
			if (this.$route.name === 'english-my-cards') {
				this.axios.get('/edu/words?favorit=1').then(({data}) => {
					this.cards = data.data;
					this.favorites = this.cards;
					
					this.categoryName = this.cards[0].category;
				});
			} else {
				this.axios.get('/edu/words?category_id=' + this.category).then(({data}) => {
					this.cards = data.data;
					
					this.categoryName = this.cards[0].category;
				});

				this.axios.get('/edu/words?favorit=1').then(({data}) => {
					this.favorites = data.data;
				});
			}
			
			this.type = localStorage.getItem('cards-type') ? localStorage.getItem('cards-type') : 'grid';
		},
		
		computed: {
			allAdded() {
				for (let i = 0; i < this.cards.length; i++) {
					let find = this.favorites.find((item) => {
						return item.id === this.cards[i].id;
					});
					
					if (!find) {
						return false;
					}
				}
				
				return true;
			}
		},

		methods: {
			loadAudio(card) {
				let audio = new Audio(this.$hostname + card.audio);
				audio.play();
			},
			setActiveCard(card) {
				this.activeCard = card;
				
				this.cards.current = this.cards.findIndex(item => item.id === this.activeCard.id);

				this.loadAudio(this.activeCard);
			},
			changeActiveCard(index) {
				if (index < 0) {
					try {
						this.setActiveCard(this.cards.prev());
					} catch(e) {
						this.activeCard = null;
					}
				} else {
					try {
						this.setActiveCard(this.cards.next());
					} catch(e) {
						this.activeCard = null;
					}
				}
			},
			changeType(type) {
				this.type = type;
				
				localStorage.setItem('cards-type', type);
			},
			print() {
				window.print();
			},
			toggleFavorite(card) {
				this.axios.get('/edu/words/add?word_id=' + card.id)
					.then(() => {
						this.axios.get('/edu/words?favorit=1').then(({data}) => {
							this.favorites = data.data;
							
							if (this.$route.name === 'english-my-cards') {
								this.cards = this.favorites;
							}
						});
					})
			},
			isFavorite(card) {
				return this.favorites.filter(x => x.id === card.id).length;
			},
			addAll() {
				this.cards.forEach((card) => {
					if (!this.isFavorite(card)) {
						this.toggleFavorite(card);
					}
				});
				
				setTimeout(() => {
					this.addedToFavorite = true;
				}, 1000)
			},
		}
	}
</script>

<style scoped>

</style>
