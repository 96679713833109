<template>
	<div>
		
	</div>
</template>

<script>
	export default {
		props: ['data'],
		
		data() {
			return {
				active: true
			}
		}
	}
</script>

<style scoped>

</style>