import Vue from 'vue';
import axios from 'axios';

Vue.prototype.axios = axios;

if (location.host.search('localhost') !== -1) {
	axios.defaults.baseURL = 'https://skill.tele2.ru';
	Vue.prototype.$hostname = 'https://skill.tele2.ru';

	axios.defaults.baseURL = '';
	Vue.prototype.$hostname = '';
} else {
	axios.defaults.baseURL = '';
	Vue.prototype.$hostname = '';
	Vue.config.local = false;
}
