<template>
	<div>
		<div class="course-banner" v-if="course">
			<div class="wrapper">
				<div class="course-banner_item __min" :style="'background-image: url(' + $hostname + course.image + ')'">
					<div class="course-banner_info">
						<router-link :to="{name: 'my-course-lessons', params: {id: course.id}}">{{ course.name }}</router-link>
					</div>
					<h1 class="course-banner_title">{{ lesson.name }}</h1>
				</div>
			</div>
		</div>
		
		<div class="lesson" v-if="lesson">
			<div class="wrapper">
				<div class="lesson_title">Материалы урока</div>
				
				<div class="lesson_items">
					<router-link :to="{name: 'my-course-lesson-material', params: {course: course.id, lesson: lesson.id, material: material.id}}" class="lesson_item" v-for="(material, key) in lesson.materials" :key="material.id">
						<div class="lesson_num">{{ key + 1 }}</div>
						<div class="lesson_view" :class="{__visible: material.ready}"></div>
						<div class="lesson_heading">{{ material.name }}</div>
						<div class="lesson_ico" :class="{__video: material.content_type === 'video', __test: material.content_type === 'test', __text: material.content_type === 'text'}">
							<i class="ico icon-movies" v-if="material.content_type === 'video'"></i>
							<i class="ico icon-included" v-else-if="material.content_type === 'test'"></i>
							<i class="ico icon-attached-file" v-else-if="material.content_type === 'text'"></i>
							<i class="ico icon-download" v-else-if="material.content_type === 'file'"></i>
							<i class="ico icon-sheets" v-else-if="material.content_type === 'html'"></i>
							<i class="ico icon-websites" v-else-if="material.content_type === 'words'"></i>
						</div>
					</router-link>
				</div>
			</div>
		</div>
		
		<div class="loading" v-if="!course"></div>
		
		<div class="wrapper back-arrows" v-if="course">
			<router-link v-if="prevLesson" :to="{name: 'my-course-lesson', params: {lesson: prevLesson.id, course: this.courseId}}" class="back-arrow __prev">
				<i class="ico icon-side-arrow"></i>
				Предыдущий урок
			</router-link>
			<router-link v-if="nextLesson" :to="{name: 'my-course-lesson', params: {lesson: nextLesson.id, course: this.courseId}}" class="back-arrow __next">
				<i class="ico icon-side-arrow"></i>
				Следующий урок
			</router-link>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				course: null,
				lesson: null,
				courseId: this.$route.params.course,
				lessonId: this.$route.params.lesson,
				prevLesson: null,
				nextLesson: null,
			}
		},
		
		created() {
			if ([99, 100, 101, 102].includes(parseInt(this.courseId))) {
				if (!this.auth.subscribed_en) {
					this.$router.push({name: 'subscribe-en'});
					return;
				}
			}
			
			this.axios.get('/edu/course/' + this.courseId).then(({data}) => {
				if (![99, 100, 101, 102].includes(parseInt(this.courseId))) {
					if (!data.course.is_free &&  this.auth.subscribed === 0) {
						this.$router.push({name: 'subscribe'});
						return;
					} else if (!data.course.is_free && this.auth.subscribed === 2) {
						this.$router.push({name: 'no-money'});
						return;
					}
				}
				
				this.course = data.course;
				
				data.lessons.forEach((lesson, key) => {
					if (lesson.id == this.lessonId) {
						this.lesson = lesson;

						data.lessons.current = key;
					}
				});
				
				this.prevLesson = data.lessons.prev();
				this.nextLesson = data.lessons.next();
			});
		}
	}
</script>

<style scoped>

</style>
