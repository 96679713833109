<template>
	<div>
		<div class="course-banner" v-if="course">
			<div class="wrapper">
				<div class="course-banner_item" :style="'background-image: url(' + $hostname + course.image + ')'">
					<div class="course-banner_info" v-if="isEnglish">
						<router-link :to="{name: 'english'}">Английский язык</router-link>
					</div>
					<div v-else class="course-banner_info">
						<router-link :to="{name: 'category', params: {id: course.category}}">{{ course.category }}</router-link>| {{ course.company_name }}
					</div>
					<h1 class="course-banner_title __mb0">{{ course.name }}</h1>
				</div>
			</div>
		</div>
		
		<div class="course-list" v-if="course">
			<div class="wrapper">
				<div class="course-list_title">Список уроков</div>
				
				<div class="course-list_items">
					<router-link :to="getUrl(course, lesson)" class="course-list_item" v-for="(lesson, key) in lessons" :key="key">
						<div class="course-list_num">{{ key + 1 }}</div>
						<div class="course-list_progress">
							<svg class="course-list_canvas" viewBox="0 0 84 84">
								<circle class="course-list_underlay" cx="42" cy="42" r="42" stroke-width="12"></circle>
								<circle v-if="lesson.progress" class="course-list_value" cx="42" cy="42" r="42" stroke-width="12" :stroke-dasharray="dasharray" :stroke-dashoffset="getProgress(lesson.progress)"></circle>
							</svg>
							<div class="course-list_percent">{{ lesson.progress ? lesson.progress : 0 }}%</div>
						</div>
						<div class="course-list_heading">{{ lesson.name }}</div>
						<div class="course-list_button">
							<div class="button">
								<span v-if="!lesson.progress">Пройти</span>
								<span v-else-if="lesson.progress !== 100">Продолжить</span>
								<span v-else-if="lesson.progress === 100">Пройти заново</span>
							</div>
						</div>
					</router-link>
				</div>
			</div>
		</div>
		
		<div class="loading" v-if="!course"></div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				dasharray: 263,
				id: this.$route.params.id,
				course: null,
				lessons: null,
			}
		},
		created() {
			if ([99, 100, 101, 102].includes(parseInt(this.id))) {
				if (!this.auth.subscribed_en) {
					this.$router.push({name: 'subscribe-en'});
					return;
				}
			}
			
			this.axios.get('/edu/course/' + this.id).then(({data}) => {
				if (![99, 100, 101, 102].includes(parseInt(this.id))) {
					if (!data.course.is_free && this.auth.subscribed === 0) {
						this.$router.push({name: 'subscribe'});
						return;
					} else if (!data.course.is_free && this.auth.subscribed === 2) {
						this.$router.push({name: 'no-money'});
						return;
					}
				}
				
				if (data.course.is_promo && !this.auth.have_promo) {
					this.$router.push({name: 'home'});
					return;
				}
				
				this.course = data.course;
				this.lessons = data.lessons;
			});
		},
		computed: {
			isEnglish() {
				return this.id == 99 || this.id == 100 || this.id == 101 || this.id == 102;
			}
		},
		methods: {
			getProgress(progress) {
				if (!progress) return this.dasharray;

				progress = 100 - progress;

				return this.dasharray * progress / 100;
			},
			
			getUrl(course, lesson) {
				if (lesson.materials.length === 1) {
					return {
						name: 'my-course-lesson-material',
						params: {
							course: course.id,
							lesson: lesson.id,
							material: lesson.materials[0].id
						}
					}
				}
				
				return {name: 'my-course-lesson', params: {course: course.id, lesson: lesson.id}}
			}
		}
	}
</script>

<style scoped>

</style>
