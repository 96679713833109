<template>
	<div>
		<div class="banner">
			<div class="wrapper">
				<div class="banner_item" style="background-image: url('/img/banner.png')">
					<div class="banner_text">Учитесь, развивайтесь, <br>прокачивайте знания!</div>
					<router-link :to="{name: 'category'}" class="button __large __white">Начать обучение</router-link>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {}
</script>

<style scoped>

</style>