import Vue from 'vue'
import axios from 'axios'

let beforeEach = (to, from, next) => {
	if (Vue.config.local) {
		fakeLogin();
		
		next(); return;
	}
	
	axios.get(Vue.prototype.$hostname + '/edu/profile').then(({data}) => {
		Vue.prototype.auth = data;
		
		let courseId = localStorage.getItem('courseId');
		let promoPage = localStorage.getItem('promoPage');
		let englishPage = localStorage.getItem('englishPage');

		if (localStorage.getItem('waitForAuth') === 'true' && data.msisdn) {
			localStorage.removeItem('waitForAuth');
			localStorage.removeItem('courseId');
			localStorage.removeItem('promoPage');
			localStorage.removeItem('englishPage');

			if (courseId) {
				location.href = '/course/' + courseId;
				return;
			}
			if (promoPage) {
				location.href = '/promo';
				return;
			}
			if (englishPage) {
				location.href = '/english';
				return;
			}
		}

		if (data.msisdn) {
			if (['english-cards', 'english-my-cards', 'english-card', 'subscribe-en'].includes(to.name)) {
				if (!data.subscribed_en) {
					if (to.name !== 'subscribe-en') {
						location.href = '/subscribe/en';
						return;
					} else {
						next(); return;
					}
				} else {
					if (to.name === 'subscribe-en') {
						location.href = '/english';
						return;
					}
				}
			}
		}
		
		if (['home', 'course', 'category', 'policy'].includes(to.name)) {
			next(); return;
		}

		if (['english', 'english-cards', 'english-my-cards', 'english-card'].includes(to.name)) {
			if (!data.msisdn) {
				localStorage.setItem('waitForAuth', 'true');
				localStorage.setItem('englishPage', 'true')
				location.href = Vue.prototype.$hostname + data.login_url;
			}
		}

		if (data.msisdn && data.subscribed) {
			if (data.subscribed === 2) {
				next(); return;
			} else {
				if (to.name === 'subscribe' || to.name === 'no-money') {
					location.href = '/'; return;
				} else {
					next(); return;
				}
			}
		} else {
			if (data.msisdn && !data.subscribed) {
				next(); return;
			} else {
				localStorage.setItem('waitForAuth', 'true');
				
				if (to.name === 'promo') {
					localStorage.setItem('promoPage', 'true')
				}
				
				location.href = Vue.prototype.$hostname + data.login_url;
			}
		}
	});
};

function fakeLogin() {
	axios.get('/edu/fake_login', ({data}) => {
		window.auth = data;
		
		console.log(data);
	});
}

export default beforeEach;
