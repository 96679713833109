<template>
	<div class="test" v-if="test">
		<div class="test_items" v-if="!finished">
			<div class="test_item" v-for="(question, key) in test.questions" :key="'question' + question.qid">
				<div class="test_question" v-html="question.title"></div>
				<div class="test_answers" v-if="question.type === 'input'">
					<div class="test_answer" v-for="answer in question.data" :key="'answer' + answer.aid">
						<input @input="answerQuestion(question, {title: $event.target.value, value: ($event.target.value.toLowerCase() === answer.title.toLowerCase()).toString()})" type="text" class="test_answer-input" placeholder="Введите ответ">
					</div>
				</div>
				<div class="test_answers" v-else>
					<div class="test_answer" v-for="answer in question.data" :key="'answer' + answer.aid">
						<label class="checkbox" v-if="isMultipleQuestion(question.data)">
							<input @change="answerQuestion(question, answer, $event.target)" type="checkbox" :name="'q-' + question.qid" class="checkbox_input">
							<span class="checkbox_ico"></span>
							<span class="checkbox_text" v-html="answer.title"></span>
						</label>
						<label class="radiobox" v-else>
							<input @change="answerQuestion(question, answer)" type="radio" :name="'q-' + question.qid" class="radiobox_input">
							<span class="radiobox_ico"></span>
							<span class="radiobox_text" v-html="answer.title"></span>
						</label>
					</div>
				</div>
			</div>
			
			<div class="test_error" v-if="activeError && !finished && !checkFinish()">Не все ответы заполнены!</div>
			
			<div class="test_button">
				<a href="" class="button __black __large" @click.prevent="finishTest">Узнать результаты</a>
			</div>
		</div>
		
		<div class="result" v-if="finished">
			<div v-if="test.questions.length > 1" class="result_title">Результаты: <span><i>{{ countAnswer['true'] }}</i>/{{ test.questions.length }}</span></div>
			
			<div class="result_text">{{ result }}</div>
			
			<div class="result_title __mt">Ответы:</div>
			
			<div class="result_items">
				<div class="result_item" v-for="(answer, key) in sortedAnswers" :key="'sorted' + key">
					<div class="result_question" :class="[questionIsCorrect(answer.question) ? '__correct' : '__wrong']">
						<em v-if="questionIsCorrect(answer.question)" class="ico icon-check-mark"></em>
						<em v-else class="ico icon-limited"></em>
						<span v-html="answer.question.title"></span>
					</div>
					<div class="result_answers" v-if="questionIsCorrect(answer.question)">
						<div class="result_answer" v-for="cAnswer in answer.question.data" :key="'cAnswer' + cAnswer.aid">
							<span v-html="cAnswer.title" class="result_answer-data" :class="{__correct: cAnswer.value === 'true'}"></span>
						</div>
					</div>
				</div>
			</div>
			
			<div class="test_button">
				<a href="" class="button __black __large" @click.prevent="restart">Пройти заново</a>
			</div>
		</div>
	</div>
</template>

<script>
	import $ from 'jquery'
	
	export default {
		props: ['url'],
		
		data() {
			return {
				test: null,
				answers: [],
				finished: false,
				activeError: false,
				result: '',
				countAnswer: {
					'true': 0,
					'false': 0
				}
			}
		},
		
		created() {
			this.axios.get(this.url)
				.then(({data}) => {
					this.test = data;
					
					this.setAnswersDefaultValues();

					this.test.questions.forEach((question) => {
						question.data.forEach((answer) => {
							this.countAnswer[answer.value] = 0;
						});
					});
				});
		},
		
		computed: {
			sortedAnswers() {
				let clone = JSON.parse(JSON.stringify(this.answers));
				
				return clone.sort((a, b) => {
					return a.question.test_id < b.question.test_id ? 1 : -1;
				}).reverse();
			}
		},
		
		methods: {
			answerQuestion(question, answer, target = null) {
				if (this.isMultipleQuestion(question.data)) {
					if (target.checked) {
						let item = this.questionInAnswers(question);
						
						if (item) {
							item.answer.push(answer);
						} else {
							this.answers.push({
								question,
								answer: [answer]
							});
						}
					} else {
						this.answers.forEach((answ) => {
							answ.answer = answ.answer.filter((item) => {
								return item.aid !== answer.aid;
							});
						})
						this.answers = this.answers.filter((answ) => {
							return answ.length;
						});
					}
				} else {
					let item = this.questionInAnswers(question);
					
					if (item) {
						item.answer = answer;
					} else {
						this.answers.push({
							question,
							answer
						});
					}
				}

			},
			setAnswersDefaultValues() {
				this.test.questions.forEach((question) => {
					question.data.forEach((answer) => {
						if (!answer.value) {
							answer.value = 'false';
						}
					})
				});
			},
			finishTest() {
				if (!this.checkFinish()) {
					this.activeError = true;
					return;
				}
				
				this.checkCountAnswers();

				this.test.results.forEach((result) => {
					let answer = this.countAnswer;
					
					if (eval(result.condition)) {
						this.result = result.description;
					}
				});
				
				this.finished = true;

				$('html, body').animate({
					scrollTop: 0
				}, 300)
			},
			checkFinish() {
				return this.test.questions.length === this.answers.length;
			},
			isMultipleQuestion(answers) {
				let cnt = 0;

				Array.prototype.forEach.call(answers, answer => {
					if (answer.value === 'true') {
						cnt++;
					}
				});
				
				return cnt > 1;
			},
			questionInAnswers(question) {
				let active = false;
				
				this.answers.forEach((item) => {
					if (item.question.qid === question.qid) {
						active = item;
					}
				});
				
				return active;
			},
			checkCountAnswers() {
				this.answers.forEach((item) => {
					let rightQuestionAnswers = 0;
					
					item.question.data.forEach((answer) => {
						if (answer.value === 'true') {
							rightQuestionAnswers++;
						}
					});
					
					let rightAnswers = 0;
					
					if (Array.isArray(item.answer)) {
						item.answer.forEach((answer) => {
							if (answer.value === 'true') {
								rightAnswers++;
							}
						});
					} else {
						rightAnswers = item.answer.value === 'true' ? 1 : 0;
					}
					
					if (rightAnswers === rightQuestionAnswers) {
						this.countAnswer['true']++;
					}
				});

				this.countAnswer['false'] = this.test.questions.length - this.countAnswer['true'];
			},
			ownerAnswer(cAnswer, answer) {
				return answer.answer.filter(item => item.aid === cAnswer.aid).length;
			},
			questionIsCorrect(question) {
				let countTrue = question.data.filter(answer => answer.value === 'true').length;
				
				let currentQuestion = this.answers.filter(item => item.question.qid === question.qid)[0];
				
				let answers = Array.isArray(currentQuestion.answer) ? currentQuestion.answer : [currentQuestion.answer];
				
				let currentQuestionCorrectCount = answers.filter(answer => answer.value === 'true').length;
				
				return countTrue === currentQuestionCorrectCount;
			},
			restart() {
				this.countAnswer = {
					'true': 0,
					'false': 0
				};
				this.result = '';
				this.finished = false;
				this.activeError = false;
				this.answers = [];
				
				$('html, body').animate({
					scrollTop: 0
				}, 300)
			},
		}
	}
</script>

<style scoped>

</style>
