<template>
	<div>
		<div class="subscribe-form">
			<div class="subscribe-form_ico">
				<img src="/img/ico-phone.svg" width="55" alt="">
			</div>
			<div class="subscribe-form_text">
				Учитесь, развивайтесь, прокачивайте знания! Станьте экспертом в любой области! Всего за 9 руб./сут. (с НДС)
			</div>
			
			<div class="subscribe-form_buttons">
				<a href="" @click.prevent="subscribe" class="button __large __black" style="margin-bottom: 10px;">Подписаться</a>
				<router-link to="/" class="button __large" style="margin-left: 15px;margin-bottom: 10px;">Отмена</router-link>
			</div>
			
			<div v-if="loading" class="subscribe-form_spinner">
				<img src="/img/spinner.gif" width="44" alt="">
			</div>
			
			<!--<div class="subscribe-form_text-2">Чтобы получить доступ, наберите со своего мобильного <strong>*745#</strong> <br><br>Стоимость 10 руб/день</div>-->
			
			
			<div v-if="smsError" class="subscribe-form_error">«Услуга не была подключена. Проверьте SMS и подтвердите подключение подписки»</div>
			<div v-if="error" class="subscribe-form_error">
				Ошибка подписки. Попробуйте еще раз
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				subscribed: null,
				error: null,
				smsError: null,
				loading: false,
				login: null,
				auth: null
			}
		},
		
		created() {
			
		},
		
		methods: {
			subscribe() {
				if (this.loading) return;
				
				this.loading = true;
				this.error = false;
				this.smsError = false;
				this.subscribed = null;
				
				this.axios.get('/edu/subscribe?action=activate');
				
				setInterval(() => {
					if (this.subscribed === 'subscribed') {
						location.href = window.historyInfo.from.fullPath;
						
						this.loading = false;
						return;
					}
					else if (this.subscribed === 'wait_1355' || this.subscribed === 'wait_1715') {
						this.smsError = true;
						this.loading = false;
						return;
					}
					else if (this.subscribed === 'subscribe_error' || this.subscribed === 'need_subscribe') {
						this.error = true;
						this.loading = false;
						return;
					}
					
					this.getSubscribedValue();
				}, 2000);
			},
			
			getSubscribedValue() {
				this.axios.get('/edu/subscribe').then(({data}) => {
					this.subscribed = data.status;
				})
			}
		},
	}
</script>

<style scoped>

</style>
