<template>
	<div>
		<banner></banner>
		
		<div class="main">
			<div class="wrapper">
				<h1 class="main_title">Направления</h1>
				
				<tab v-if="courses" :headings="directions" :active-key="'new'">
					<template slot="new">
						<div class="courses">
							<div class="courses_row">
								<div class="courses_col"  v-for="course in newCourses" :key="course.id">
									<course-item :course="course"></course-item>
								</div>
								
								<div class="courses_button">
									<router-link :to="{name: 'category', params: {id: category}}" class="button __large">Смотреть все</router-link>
								</div>
							</div>
						</div>
					</template>
					
					<template :slot="category" v-for="(courseItem, category) in courses">
						<div class="courses">
							<div class="courses_row">
								<div class="courses_col"  v-for="course in courseItem" :key="course.id">
									<course-item :course="course"></course-item>
								</div>
								
								<div class="courses_button">
									<router-link :to="{name: 'category', params: {id: category}}" class="button __large">Смотреть все</router-link>
								</div>
							</div>
						</div>
					</template>
				</tab>
				<div v-else class="loading"></div>
			</div>
		</div>
		
		<div class="plus">
			<div class="wrapper">
				<div class="plus_inner">
					<h2 class="plus_title">Преимущества</h2>
					
					<div class="plus_items">
						<div class="plus_item">
							<div class="plus_image">
								<i class="ico icon-balance"></i>
							</div>
							
							<div class="plus_content">
								<h3>Гибкий формат оплаты</h3>
								<p>Возможность платить за каждый день занятий отдельно.</p>
							</div>
						</div>
						<div class="plus_item">
							<div class="plus_image">
								<i class="ico icon-clock"></i>
							</div>
							
							<div class="plus_content">
								<h3>Удобный график</h3>
								<p>Вы сами решаете, когда заниматься и сколько времени уделять обучению. <br>Не нужно подстраиваться под группу или преподавателя.</p>
							</div>
						</div>
						<div class="plus_item">
							<div class="plus_image">
								<i class="ico icon-fast-action-1"></i>
							</div>
							
							<div class="plus_content">
								<h3>Онлайн обучение</h3>
								<p>Обучение проходит в онлайн-формате. Занимайтесь где угодно, <br>с любого доступного вам устройства.</p>
							</div>
						</div>
						<div class="plus_item">
							<div class="plus_image">
								<i class="ico icon-magazine"></i>
							</div>
							
							<div class="plus_content">
								<h3>Системный подход</h3>
								<p>Вы получите актуальные систематизированные знания от мастеров <br>своего дела. Тесты по окончанию курсов помогут закрепить информацию<br> надолго и оценить достигнутый результат.</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		
		<div class="companies">
			<div class="wrapper">
				<h2 class="plus_title">Размещают курсы</h2>
				
				<div class="companies_row">
					<div class="companies_col" v-for="company in companies" :key="company.id">
						<router-link :to="{name: 'company', params: {id: company.id}}" class="companies_item">
							<div class="companies_logo">
								<img :src="$hostname + company.company_logo" alt="">
							</div>
							<div class="companies_name">{{ company.name }}</div>
						</router-link>
					</div>
				</div>
			</div>
		</div>
		
		<div class="courses-2 __bg" v-if="courses">
			<div class="wrapper">
				<h2 class="courses-2_title">Популярное</h2>
				
				<div class="courses_row">
					<div class="courses_col"  v-for="course in popularCourses" :key="course.id">
						<course-item :course="course"></course-item>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Tab from "../components/Tab/Tab";
	import Banner from "../components/Layout/Banner";
	import CourseItem from "../components/Course/Item";

	export default {
		components: {
			Tab,
			Banner,
			CourseItem
		},
		data() {
			return {
				courses: null,
				newCourses: null,
				directions: {},
				activeTab: '',
				popularCourses: null,
				companies: null
			}
		},
		created() {
			this.axios.get('/edu/companies').then(({data}) => {
				this.companies = data;
			});
			
			this.axios.get('/edu/courses').then(({data}) => {
				this.courses = {};
				
				data.data.forEach((course) => {
					if (!this.courses[course.category]) {
						this.courses[course.category] = [course];
					} else {
						if (this.courses[course.category].length < 6) {
							this.courses[course.category].push(course);
						}
					}
				});
				
				this.popularCourses = data.data.filter(course => course.is_popular === true);
			});
			
			this.axios.get('/edu/courses?is_new=1').then(({data}) => {
				this.newCourses = data.data;
			});
			
			this.directions['new'] = 'Новинки';
			
			this.categories.forEach((category) => {
				this.directions[category] = category;
			});
		},
	}
</script>

<style scoped>

</style>
