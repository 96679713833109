<template>
	<div>
		<div class="subscribe-form">
			<div class="subscribe-form_ico">
				<img src="/img/ico-phone.svg" width="55" alt="">
			</div>
			<div class="subscribe-form_text">Доступ ограничен. Пожалуйста пополните Ваш счет мобильного телефона и повторите попытку позднее.</div>
			<br>
			
			
			<router-link :to="{name: 'home'}" class="button __black">Вернуться</router-link>
		</div>
	</div>
</template>

<script>
	export default {}
</script>

<style scoped>

</style>