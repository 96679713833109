<template>
	<div id="app" v-if="categories">
		<header-block></header-block>
		
		<router-view :key="$route.fullPath"></router-view>
		
		<footer-block></footer-block>
	</div>
</template>

<script>
	import Vue from 'vue';
	import Header from "./components/Layout/Header";
	import Footer from "./components/Layout/Footer";
	
	export default {
		name: 'App',
		
		data() {
			return {
				categories: null,
				categoriesColors: {
					'Личностный рост': {
						icon: 'icon-fast-action-1',
						color: '#64D49E'
					},
					'Финансы': {
						icon: 'icon-balance',
						color: '#FF59A3'
					},
					'Маркетинг': {
						icon: 'icon-penguin',
						color: '#C882FF'
					},
					'Менеджмент': {
						icon: 'icon-my-services',
						color: '#ffe6f1'
					},
					'IT': {
						icon: 'icon-internet',
						color: '#FF59A3'
					},
					'Дизайн': {
						icon: 'icon-fire',
						color: '#E1FF66'
					},
					'Бизнес и управление': {
						icon: 'icon-all-services',
						color: '#38FFA6'
					},
					'Общеобразовательные дисциплины': {
						icon: 'icon-edit',
						color: '#3FCBFF'
					},
				}
			}
		},
		
		components: {
			HeaderBlock: Header,
			FooterBlock: Footer
		},
		
		created() {
			if (Vue.config.local) {
				Vue.prototype.categories = [
					"IT",
					"Бизнес и управление",
					"Дизайн",
					"Личностный рост",
					"Маркетинг",
					"Общеобразовательные дисциплины"
				];
				this.categories = Vue.prototype.categories;
			} else {
				this.axios.get('/edu/categories')
					.then(({data}) => {
						Vue.prototype.categories = data;
						this.categories = data;
					});
			}

			Vue.prototype.categoriesColors = this.categoriesColors;
		}
	}
</script>

<style lang="less">
	@import "./assets/less/main";
</style>
