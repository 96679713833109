<template>
	<div>
		<div class="course-banner">
			<div class="wrapper">
				<div class="course-banner_item __opacity" :style="'background-image: url(/img/eng.jpg)'">
					<div class="course-banner_info">
						<router-link :to="{name: 'english'}">Английский язык</router-link>
					</div>
					
					<h1 class="course-banner_title">Все наборы</h1>
					
					<div class="course-banner_buttons" >
						<router-link v-if="$route.name === 'english-my-cards'" :to="{name: 'english-cards'}" class="course-banner_button __margin">Все наборы</router-link>
						<router-link v-if="$route.name === 'english-cards'" :to="{name: 'english-my-cards'}" class="course-banner_button __margin">Избранное</router-link>
					</div>
				</div>
			</div>
		</div>
		
		<div class="cards">
			<div class="wrapper">
				<div class="cards_row">
					<div class="cards_col" v-for="category in categories" :key="category.id">
						<router-link :to="{name: 'english-card', params: { category: category.id }}" class="cards_item">
							<div class="cards_image">
								<img :src="$hostname + category.image" alt="">
							</div>
							<div class="cards_title">
								<span>{{ category.name }}</span>
								<span>Набор из {{ category.cnt }} слов</span>
							</div>
						</router-link>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				categories: null
			}
		},
		
		created() {
			this.axios.get('/edu/words/categories').then(({data}) => {
				this.categories = data;
			})
		},
		
		methods: {
			urlSlug(val) {
				return window.urlSlug(val);
			}
		}
	}
</script>

<style scoped>

</style>
