<template>
	<router-link :to="{name: 'course', params: {id: course.id}}" class="courses_item">
		<div class="courses_top" :style="background">
			<div class="courses_top-text" v-if="!promo">{{ course.category }}</div>
			<div class="courses_top-logo">
				<img :src="$hostname + course.company_logo" alt="">
			</div>
		</div>
		<div class="courses_main">
			<div class="courses_main-row">
				<div class="courses_content">
					<div class="courses_title">{{ course.name }}</div>
					<div class="courses_description">{{ course.short_descr }}</div>
				</div>
			</div>
		</div>
		
		<div class="courses_bottom">
			<img src="/img/time.png" alt="">
			
			<span>{{ course.duration }}</span>
			
			<span v-if="!promo" class="courses_free">
				<span v-if="course.is_free">Бесплатно</span>
				<span v-else>По подписке</span>
			</span>
		</div>
	</router-link>
</template>

<script>
	export default {
		props: ['course', 'promo'],
		
		computed: {
			background() {
				if (this.categoriesColors[this.course.category]) {
					return 'background-color: ' + this.categoriesColors[this.course.category].color;
				}
				return '';
			},
			icon() {
				if (this.categoriesColors[this.course.category]) {
					return [this.categoriesColors[this.course.category].icon]
				}
				return [];
			}
		},
	}
</script>

<style scoped>

</style>
