import Vue from 'vue';
import App from './App.vue';
import router from "../router";

require('./plugins');

window.events = new Vue();

Vue.prototype.auth = {};

Vue.config.local = location.href.search('localhost') !== -1;

window.historyInfo = {
	from: '',
	to: ''
};

new Vue({
	render: h => h(App),
	router,
}).$mount('#app')
