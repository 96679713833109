<template>
	<div>
		<div class="policy">
			<div class="wrapper">
				<h1>Правила предоставления услуги «Доступ к «Образовательному порталу
					Tele2» для Абонентов Оператора Tele2 (далее – Правила)</h1>
				<p>Данные положения являются обязательными для исполнения и озвучиваются в настоящих
				   правилах на предоставление физическим лицам, пользующимся услугами подвижной
				   радиотелефонной связи под товарным знаком Tele2 (далее – Абонент), доступа к сервису
				   «Образовательный портал Tele2», далее – «Сервис».</p>
				<p>1. Воспользоваться услугой «Доступ к «Образовательному порталу Tele2» (далее –
				   «Услуга») на указанных ниже условиях могут только Абоненты, заключившие договор с
				   любым из операторов связи, предоставляющим услуги подвижной радиотелефонной связи
				   под товарным знаком «Tele2» (далее – «Оператор»).</p>
				<p>2. Для заказа Услуги Абонент должен располагать оборудованием, необходимым для
				   оформления заказа и пользования Сервисом, включая, но не ограничиваясь: мобильный
				   телефон (или другое мобильное устройство доступа), который находится в рабочем
				   состоянии и обеспечивает Абоненту доступ к услугам Оператора, посредством
				   подключения данного конечного оборудования к сети связи Оператора.</p>
				<p>3. В рамках оказания Услуги Оператор обеспечивает Абоненту доступ к Сервису
				   посредством сети связи Оператора и несет ответственность за работоспособность сети
				   связи и качественное предоставление доступа к Сервису посредством сети связи.</p>
				<p>В рамках Услуги Абоненту обеспечивается доступ к Сервису, где Абонент может:</p>
				<p>3.1.1. зарегистрироваться</p>
				<p>3.1.2. осуществлять навигацию по Сервису</p>
				<p>3.1.3. изучать текстовый, фото-и видео-контент и др.</p>
				<p>4. Сервис предоставляется Сервис-провайдером (далее также может именоваться –
				   Контент-провайдер, Провайдер, Партнер): Общество с ограниченной ответственностью
				   «Фабрика мобильного контента» (ОГРН 1057748975393, ИНН 7724560137, юр. адрес:
				   140105, г. Раменское, Северное шоссе, д.10., офис 303).</p>
				<p>5. Сервис-провайдер несет полную ответственность за соответствие информации,
				   предоставляемой Абоненту в рамках Сервиса, действительности и действующему
				   законодательству Российской Федерации.</p>
				<p>6. Оператор не несет ответственности за возможный причиненный Абоненту ущерб,
				   вызванный противоправными действиями Сервис-провайдера, а также за содержание
				   Сервиса и любую информацию, размещенную на сайтах Сервис-провайдера или его
				   партнеров, к которым Абонент получил доступ.</p>
				<p>7. Заказ Услуги осуществляется Абонентом в целях получения доступа к Сервису
				   путем совершения с использованием абонентского устройства, подключенного к сети связи
				   Оператора, конклюдентных действий следующим путем:</p>
				<p>7.1. отправки USSD-запросов: *803# или *803*[любые цифры]# и следования
				   дальнейшим указаниям USSD-меню;</p>
				<p>7.2. совершения действий в определенной последовательности, согласно информации,
				   отраженной на информационном ресурсе в сети Интернет <a href="https://skill.tele2.ru/">https://skill.tele2.ru/</a> (например,
				   нажатия кнопки «Подключить», «Подписаться» или «Купить») с обязательным указанием
				   телефонного номера, по которому оборудование Абонента идентифицируется в сети
				   электросвязи Оператора. Ввод телефонного номера не требуется, если он в автоматическом
				   режиме определен сетевым оборудованием Оператора.</p>
				<p>7.3. ответа на push-сообщение, отправка которого инициирует отправку USSDзапроса: *803#.
				   Совершение действий, указанных в п. 7., будет являться безусловным подтверждением и
				   согласием Абонента с условиями <a href="http://apps.tele2.ru/privacy-policy.pdf">Политики конфиденциальности Tele2</a>.</p>
				<p>8. При предоставлении Услуги могут использоваться различные способы привлечения
				   Абонентов к пользованию Сервисом, включая, но не ограничиваясь: баннеры, тизеры,
				   редиректы.</p>
				<p>9. Оказание Услуги осуществляется на условиях подписки: плата для Абонента за
				   пользование услугой устанавливается в размере 9 (девяти) рублей 00 копеек в день, включая
				   НДС. Плата списывается Оператором с контентного лицевого счета Абонента, а при его
				   отсутствии – с основного лицевого счета Абонента ежедневно за каждый день
				   предоставления услуги.</p>
				<p>10. Осуществляя заказ Услуги, Абонент:</p>
				<p>10.1. подтверждает свое ознакомление и согласие с настоящими Правилами;</p>
				<p>10.2. подтверждает свое согласие на получение Услуги в соответствии с условиями,
				   изложенными в настоящих Правилах;</p>
				<p>10.3. подтверждает факт выполнения всех требований, предъявляемых настоящими
				   Правилами к Абоненту, а также принимает на себя обязательство следовать этим
				   требованиям в период использования Услуги;</p>
				<p>10.4. подтверждает, что дает согласие Оператору и Сервис-провайдеру на обработку
				   данных (в том числе персональных данных), предоставленных Абонентом при заказе
				   Услуги и при пользовании Сервисом, а также полученных Оператором, при заключении и
				   исполнении договора на оказание услуг связи, либо полученных в автоматическом режиме
				   от технических средств Сервис-провайдера. Обработка данных осуществляется в
				   соответствии с действующим законодательством Российской Федерации и в соответствии
				   с положениями Политики конфиденциальности при пользовании услугами Tele2. Абонент
				   обязан самостоятельно ознакомиться с <a href="http://apps.tele2.ru/privacy-policy.pdf">Политикой конфиденциальности Tele2</a>. Данные
				   обрабатываются в целях надлежащего предоставления Абоненту Услуги и Сервиса
				   следующими способами: сбор, запись, систематизация, накопление, хранение, извлечение,
				   использование, предоставление, блокирование, удаление, уничтожение. Получение от
				   Абонента указанного согласия не предполагает обязательное использование/обработку
				   Оператором и/или Сервис-провайдером данных Абонента;</p>
				<p>10.5. выражает согласие на отправку ему с коротких сервисных номеров бесплатных
				   SMS-сообщений, напоминающих Абоненту о содержании, стоимости, порядке отказа от
				   Услуги, обновлениях Сервиса и его функционала, а также содержащих контент иных
				   Абонентов- пользователей Сервиса;</p>
				<p>10.6. обязуется оплачивать услуги связи и/или иные услуги Оператора и Сервиспровайдера, оказанные при использовании Услуги;</p>
				<p>10.7. гарантирует, что будет пользоваться Сервисом только в личных целях.
				   Использование Сервиса любым способом, не предусмотренным настоящими Правилами,
				   включая, но не ограничиваясь, способом его перепродажи или распространения иным
				   образом (как в целом, так и их составных частей) не допускается;</p>
				<p>10.8. обязуется не осуществлять действия, направленные на нарушение
				   функционирования Сервиса или получение несанкционированного доступа
				   к программному обеспечению Сервиса;</p>
				<p>10.9. соглашается, что Оператор и Сервис-провайдер не несут ответственности за контент
				   иных Абонентов- пользователей Сервиса, получаемый Абонентом при пользовании
				   Сервисом;</p>
				<p>10.10. подтверждает, что является совершеннолетним и вправе регистрироваться
				   в Сервисе;</p>
				<p>11. Выполнение Абонентом указанных в п. 7 настоящих Правил действий означает
				   согласие Абонента на получение Сервиса, а также на передачу данных,
				   идентифицирующих Абонента (в частности, номера мобильного телефона Абонента)
				   третьим лицам, в том числе Сервис — провайдеру для предоставления Сервиса.</p>
				<p>12. Абонент вправе в любое время отказаться от Услуги, одним из указанных ниже
				   способов:</p>
				<p>● путем отправки USSD-запроса *803*0#;</p>
				<p>● в личном кабинете на сайте tele2.ru/lk.</p>
				<p>При отказе от Услуги она предоставляется Абоненту до истечения ранее оплаченного
				   Абонентом периода использования услуги.</p>
				<p>В случае прекращения оказания Абоненту Услуги восстановление Услуги производится
				   только после повторного выполнения Абонентом действий, указанных в пункте 6
				   настоящих Правил.</p>
				<p>13. В случае невозможности списания с лицевого счета (лицевого контентного счета)
				   Абонента денежных средств за Услугу в полном размере (в силу отсутствия достаточного
				   количества денежных средств на лицевом счете Абонента), Услуга может быть
				   предоставлена Абоненты с последующей дотарификацией.</p>
				<p>14. В зависимости от условий тарификации Услуга предоставляется с момента
				   принятия настоящих Правил, либо на условиях подписки, либо единовременно. В случае
				   предоставления Услуги на условиях подписки, Услуга предоставляется и тарифицируется
				   до момента ее отключения Абонентом. В случае предоставления Услуги единовременно
				   Услуга предоставляется и тарифицируется по факту обработки заказа Услуги и прекращает
				   свое действие в момент потребления Услуги (не требует отключения Абонентом).</p>
				<p>15. В случае некорректного предоставления Услуги или наличия претензий,
				   касающихся ее подключения и предоставления, Абонент обязан уведомить об этом Сервиспровайдера по e-mail: support_tele2@roscontent.ru.
				<p>16. Настоящие Правила в соответствии с действующим законодательством Российской</p>
				   Федерации являются предложением (публичной офертой) заключить договор
				   присоединения о предоставлении Услуги на изложенных в настоящих Правилах условиях.
				<p>17. Настоящие Правила могут быть в одностороннем порядке изменены путем
				   размещения новой редакции Правил по адресу: https://skill.tele2.ru/info. В этом случае новая
				   редакция Правил будет считаться вступившей в силу с момента такого размещения.</p>
				<p>18. Оператор вправе без предупреждения приостановить использование Абонентом
				   мобильного приложения в случаях:</p>
				<p>18.1. нарушения Абонентом условий данных Правил;</p>
				<p>18.2. подозрения на совершение Абонентом противозаконных действий вплоть
				   до выяснения обстоятельств, если иное прямо не предусмотрено применимым
				   законодательством.</p>
				<p>19. Размещая свой контент при пользовании Услугой, в том числе через мобильное
				   приложение, Абонент гарантирует, что является автором данного контента и/или имеет
				   согласие/разрешение от его авторов и/или иных правообладателей на его использование
				   способами, предусмотренными настоящей статьей. Абонент безвозмездно предоставляет
				   Сервис-провайдеру и Оператору (с правом передачи третьим лицам, в том числе, в целях
				   улучшения работы мобильного приложения), простую (неисключительную) лицензию
				   на использование данного контента в течение всего срока действия исключительных прав
				   на территории всего мира путем копирования, публичного исполнения, публичного показа,
				   доведения до всеобщего сведения, воспроизведения, переработки, перевода
				   и распространения, а также дает разрешение на создание производных произведений
				   и использование контента в качестве составных частей в составе любых сборников. Кроме
				   того, Абонент предоставляет другим Абонентам неисключительную лицензию
				   на использование контента путем просмотра, воспроизведения исключительно с целью
				   личного некоммерческого использования, на переработку (в том числе на распечатку
				   копий).</p>
			
			</div>
		</div>
	</div>
</template>

<script>
	export default {}
</script>

<style scoped>

</style>
