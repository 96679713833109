<template>
	<div>
		<div class="course-banner">
			<div class="wrapper">
				<div class="course-banner_item __opacity" :style="'background-image: url(/img/eng.jpg)'">
					<h1 class="course-banner_title __mb0">Английский язык</h1>
				</div>
			</div>
		</div>
		
		<div class="english">
			<div class="wrapper">
				<h2 class="english_title">Курсы</h2>
				
				<div class="english_cards">
					<div class="english_card">
						<router-link :to="{name: 'my-course-lessons', params: {id: 99}}" class="english_card-item">
							<div class="english_card-image">
								<img src="/img/category1.png" alt="">
							</div>
							
							<div class="english_card-text">Beginner</div>
							
							<span class="english_card-description">Уровень 1: Набор для выживания</span>
						</router-link>
					</div>
					<div class="english_card">
						<router-link :to="{name: 'my-course-lessons', params: {id: 100}}" class="english_card-item">
							<div class="english_card-image">
								<img src="/img/category2.png" alt="">
							</div>
							
							<div class="english_card-text">Elementary</div>
							
							<span class="english_card-description">Уровень 2: Поддержите любую беседу</span>
						</router-link>
					</div>
					<div class="english_card">
						<router-link :to="{name: 'my-course-lessons', params: {id: 101}}" class="english_card-item">
							<div class="english_card-image">
								<img src="/img/category3.png" alt="">
							</div>
							
							<div class="english_card-text">Intermediate</div>
							
							<span class="english_card-description">Уровень 3: Оттачиваем мастерство</span>
						</router-link>
					</div>
					<div class="english_card">
						<router-link :to="{name: 'my-course-lessons', params: {id: 102}}" class="english_card-item">
							<div class="english_card-image">
								<img src="/img/category4.png" alt="">
							</div>
							
							<div class="english_card-text">Upper-Intermediate</div>
							
							<span class="english_card-description">Уровень 4: Чемпионский</span>
						</router-link>
					</div>
				</div>
				
				<div class="english_words">
					<div class="english_words-image">
						<img src="/img/english/levels/cards.png" alt="">
					</div>
					<div class="english_words-content">
						<div class="english_words-title">Карточки английских слов</div>
						<p>
							Развивайте свой словарный запас, тренируясь ежедневно в онлайн режиме. <br><br>
							Карточки разбиты по темам и категориям, это помогает удобно и просто запоминать новые слова.
						</p>
						<router-link :to="{name: 'english-cards'}" class="button __large">Перейти</router-link>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				categories: null
			}
		},

		created() {
			this.axios.get('/edu/words/categories').then(({data}) => {
				this.categories = data;
			})
		},

		methods: {
			urlSlug(val) {
				return window.urlSlug(val);
			}
		}
	}
</script>

<style scoped>

</style>
