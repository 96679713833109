<template>
	<div>
		<div class="main __pt">
			<div class="wrapper">
				<h1 class="main_title">
					<span v-if="$route.params.id">{{ $route.params.id }}</span>
					<span v-else>Все курсы</span>
				</h1>
				
				<div class="tab">
					<div class="tab_header">
						<router-link exact :to="{name: 'category'}">Все курсы</router-link>
						<router-link v-for="category in categories" :key="category" :to="{name: 'category', params: {id: category}}">{{ category }}</router-link>
					</div>
				</div>
			</div>
		</div>
		
		<div class="courses-2" v-if="courses">
			<div class="wrapper">
				<div class="courses_row">
					<div class="courses_col"  v-for="course in courses" :key="course.id">
						<course-item :course="course"></course-item>
					</div>
				</div>
				
				<div class="courses-2_button" v-if="loadedLen && loadedLen === limit && !loading">
					<a @click.prevent="getNextPage" href="" class="button">Показать еще</a>
				</div>
				<div class="loading" v-else-if="loading"></div>
			</div>
		</div>
		<div v-else class="loading"></div>
	</div>
</template>

<script>
	import $ from 'jquery'
	
	import Tab from "../components/Tab/Tab";
	import Banner from "../components/Layout/Banner";
	import CourseItem from "../components/Course/Item";

	export default {
		components: {
			Tab,
			Banner,
			CourseItem
		},
		data() {
			return {
				id: this.$route.params.id,
				courses: null,
				limit: 12,
				page: 1,
				loading: false,
				endLoading: false,
				loadedLen: null
			}
		},
		created() {
			let params = {
				limit: this.limit,
				offset: 0
			};
			
			if (this.id) {
				params['category'] = this.id;
			}
			
			this.axios.get('/edu/courses', {params: params}).then(({data}) => {
				this.courses = data.data;

				this.loadedLen = data.data.length;
			});
			
			document.onscroll = () => {
				let scrollEnd = (window.innerHeight + window.scrollY) >= document.body.offsetHeight - $('.footer-main').innerHeight();
				
				if (scrollEnd) {
					this.getNextPage();
				}
			};
		},
		
		methods: {
			getNextPage() {
				if (this.loading || this.loadedLen < this.limit) return;
				
				this.loading = true;
				
				let params = {
					limit: this.limit,
					offset: this.limit * this.page
				};

				if (this.id) {
					params['category'] = this.id;
				}

				this.axios.get('/edu/courses', {params: params}).then(({data}) => {
					this.courses = this.courses.concat(data.data);
					
					this.page++;
					this.loading = false;
					this.loadedLen = data.data.length;
				});
			}
		}
	}
</script>

<style scoped>

</style>