<template>
	<div class="footer-main">
		<div class="footer-top" v-if="$route.name === 'subscribe'">
			<div class="wrapper">
				<div class="footer_text-2">
					Нажимая кнопку «Получить доступ», вы подтверждаете заказ услуги «Доступ к «Образовательному порталу Tele2» на условиях
					<router-link to="/info">пользовательского соглашения</router-link>. Провайдер ООО «ФМК». Стоимость: 9 руб./сут. (с НДС). Оплата с контентного лицевого счета, а при его отсутствии — с основного лицевого счета Абонента.
				</div>
			</div>
		</div>
		
		<footer class="footer">
			<div class="wrapper">
				<div class="footer_row">
					<div class="footer_text">6+ © Tele2 Россия, {{ year() }}</div>
					<div class="footer_text-3">Продолжая использовать наш сайт, вы даете согласие на обработку файлов cookie в соответствии с <a href="https://tele2.ru/api/media/content?contentId=m3100004">Политикой Компаний Группы Tele2</a> в области использования файлов cookie</div>
					<router-link v-if="isEnglishPage" to="/info-en" target="_blank" class="footer_link">Условия сервиса</router-link>
					<router-link v-else to="/info" target="_blank" class="footer_link">Условия сервиса</router-link>
				</div>
			</div>
		</footer>
	</div>
</template>

<script>
	import moment from 'moment';

	export default {
		methods: {
			year() {
				return moment().format('Y')
			}
		},
		computed: {
			isEnglishPage() {
				let routeName = this.$route.name;

				
				if (['my-course-lessons', 'my-course-lesson', 'my-course-lesson-material'].includes(routeName)) {
					let course = this.$route.params.course ? parseInt(this.$route.params.course) : parseInt(this.$route.params.id);
					
					if ([99, 100, 101, 102].includes(course)) {
						return true;
					}
				}
				
				let routes = ['english', 'english-cards', 'english-my-cards', 'english-card'];

				return routes.includes(routeName);
			}
		}
	}
</script>
