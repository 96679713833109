<template>
	<div>
		<div v-if="courses && courses.length" class="my">
			<div class="wrapper">
				<h2 class="h1-like">Мои курсы</h2>
				
				<div class="my_courses">
					<div class="my_course" v-for="course in courses" :key="course.id">
						<router-link :to="{name: 'my-course-lessons', params: {id: course.id}}" class="my_course-inner">
							<div class="my_course-top" :style="getBackground(course)">
								<div class="my_course-top-text">{{ course.category }}</div>
								<div class="my_course-top-logo">
									<img :src="$hostname + course.company_logo" alt="">
								</div>
							</div>
							
							<div class="my_course-main">
								<div class="my_course-title">{{ course.name }}</div>
								
								<div class="my_course-progress">
									<svg class="my_course-canvas" viewBox="0 0 84 84">
										<circle class="my_course-underlay" cx="42" cy="42" r="42" stroke-width="12"></circle>
										<circle v-if="course.progress" class="my_course-value"
												cx="42" cy="42" r="42"
												stroke-width="12"
												:stroke-dasharray="dasharray"
												:stroke-dashoffset="getProgress(course.progress)"></circle>
									</svg>
									<div class="my_course-ico">
										<img src="/img/light.svg" alt="">
									</div>
								</div>
								<div class="my_course-progress-data">Пройдено: {{ course.progress ? course.progress : 0 }}%</div>
							</div>
							
							<div class="my_course-bottom">
								<div class="my_course-status"><i class="ico icon-success-large"></i>Доступен</div>
							</div>
						</router-link>
					</div>
				</div>
			</div>
		</div>
		<div v-else-if="loading" class="loading"></div>
		<div v-else class="text-block">
			<div>На данный момент вы не проходите курсы</div>
			<br>
			<router-link :to="{name: 'home'}" class="button __black">Выбрать курс</router-link>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				dasharray: 263,
				courses: null,
				loading: true
			}
		},
		created() {
			this.axios.get('/edu/courses?in_progress=1').then(({data}) => {
				this.courses = data.data;
				this.loading = false;
			});
		},
		
		methods: {
			getBackground(course) {
				if (this.categoriesColors[course.category]) {
					return 'background-color: ' + this.categoriesColors[course.category].color;
				}
				return '';
			},
			getProgress(progress) {
				if (!progress) return this.dasharray;
				
				progress = 100 - progress;
				
				return this.dasharray * progress / 100;
			}
		}
	}
</script>